.profile-detail-container {
  .profile-detail-title {
    color: black;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .profile-detail-content-item-header {
    color: black;
    font-size: 18px;
    font-weight: 500;
    margin-top: -10px;
  }

  .profile-detail-content-item-body {
    margin-top: -10px;
    color: #525555;
    font-size: 16px;
    font-weight: 500;
    margin-top: -10px;
  }

  .profile-detail-subtitle {
    color: black;
    font-size: 20px;
    font-weight: 700;
    margin-left: 15px;
    margin-bottom: 30px;
  }

  .profile-detail-content-container {
    margin-top: -15px;

    .profile-detail-content-header {
      color: grey;
      font-size: 15px;
      font-weight: 500;
    }
    .profile-detail-content-body {
      color: black;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.change-avatar-modal {
  .avatar-container {
    .avatar-item-active {
      border: 4px solid #198754;
    }
    .avatar-item:hover {
      border: 4px solid #dc3545;
      cursor: pointer;
    }
  }
}

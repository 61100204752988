.custom-table-emp {
  border-collapse: collapse;
  width: 100%;
}
.custom-table-emp td, .custom-table-emp th {
  border: 1px solid #d9d9d9;
}
.custom-table-emp tr:first-child th {
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}
/* .custom-table tr:last-child td {
  border-bottom: 0;
} */
.custom-table-emp tr td:first-child,
.custom-table-emp tr th:first-child {
  border-left: 1px solid #d9d9d9;
}
.custom-table-emp tr td:last-child,
.custom-table-emp tr th:last-child {
  border-right: 1px solid #d9d9d9;
}

.custom-table-emp-exp {
  border-collapse: collapse;
  width: 80%;
 
}

.overflow {
  overflow: auto;
}
.custom-table-emp-exp td, .custom-table-emp-exp th {
  border: 1px solid #d9d9d9;
}
.custom-table-emp-exp tr:first-child th {
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
}
.custom-table-emp-exp tr td:first-child,
.custom-table-emp-exp tr th:first-child {
  border-left: 1px solid #d9d9d9;
}
.custom-table-emp-exp tr td:last-child,
.custom-table-emp-exp tr th:last-child {
  border-right: 1px solid #d9d9d9;
}

.btn-border {
  border:1.5px solid #d9d9d9;
  color: black;
}

.btn-border:hover {
  background: #DC3545;
  color: white;
}

.rm-left-border {
border-left: 0;
}

/* .modal {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
} */

/* .modal {
width: auto;
height: 800px;
background-color: #ffffff;
border: 1px solid #cccccc;
padding: 20px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
box-shadow: 0px 0px 10px #cccccc;
border-radius: 10px;
z-index:1000;
}

.modal-dialog {
width: 1200px;
}

.modal-content {
width: auto;
} */


.content-left {
width: 65%;
float: left;
}

.content-right {
text-align: center;
margin-top: 60px;
width: 25%;
float: right;
}


.custom-nav .nav-tabs .nav-item .nav-link.active {
  background-color: #ffffff;
  color: #46e919;
  font-weight: bold;
  font-size: 14pt;
  }

.nav-tabs .nav-item .nav-link {
color: rgb(215, 164, 164);
border: 0.5px solid lightgrey;
}


/* FILE UPLOAD */


.drop-file-input {
position: relative;
width: 100%;
height: 200px;
border: 2px dashed black;
border-radius: 20px;

display: flex;
align-items: center;
justify-content: center;

background-color: white;
}

.drop-file-input input {
opacity: 0;
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
opacity: 0.6;
}

.drop-file-input__label {
text-align: center;
color: var(--txt-second-color);
font-weight: 600;
padding: 10px;
}

.drop-file-input__label img {
width: 100px;
}

.drop-file-preview {
margin-top: 50px;
}

.drop-file-preview p {
font-weight: 500;
}

.drop-file-preview__title {
margin-bottom: 20px;
}

.drop-file-preview__item {
position: relative;
display: flex;
margin-bottom: 10px;
/* background-color: var(--input-bg); */
padding: 15px;
border-radius: 20px;
}

.drop-file-preview__item img {
width: 50px;
margin-right: 20px;
}

.drop-file-preview__item__info {
display: flex;
flex-direction: column;
justify-content: space-between;
}

.drop-file-preview__item__del {
background-color: red;
width: 40px;
height: 40px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
right: 10px;
top: 50%;
transform: translateY(-50%);
box-shadow: gray;
cursor: pointer;
opacity: 0;
transition: opacity 0.3s ease;
font-size: 20;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
opacity: 1;
}

.custom-file-upload {
/* opacity: 0; */
color: transparent;
position: absolute;
top: 10;
left: 0;
width: 35%;
height: 40px;
cursor: pointer;
/* border: solid black; */
margin-left: 20px;
color: red;
font-weight: 500;
}

.custom-file-upload input{
opacity: 0;
position: absolute;
margin-left: -110px;
margin-top: -5px;
color:transparent;
width: 100%;
height: 100%;
border: solid black;
}

.custom-file-upload input:hover{
color:transparent;
width: 100%;
height: 100%;
background-color: #DC3545;
color: white;
}

.fa-cloud-arrow-down{
color: "#dc3545"
}

.fa-filter {
color: "#dc3545"
}

.fa:hover .fa-cloud-arrow-down{
color: white;
}

.fa:hover .fa-filter {
color: white;
}

.nowrap {
white-space:nowrap;
}

.btn-custom-filter{
background-color: #DC3545;
color: white;
}

/* add monic */
.link-whatsapp {
  font-size: 12pt;
  position: absolute;
  top: 52%;
  left : 43%;
  color: blue;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.btn-outline-danger:hover {
  background : #eb858f;
}

.btn-danger:hover {
  background: #f1efef;
  color: #dba6ac;
}

.btn-warning:hover {
  background: #f1efef;
  color: #d3a50f;
}

  .table-content {
    margin-top: 80px;
    margin-left : -20px;
    margin-bottom: 70px;
  }

  .table-content-function-clients {
    margin-top: 10px;
    margin-left: 20px;
    width: 95%;
  }

  /* unsync style */
  .select-unsyc {
    margin-left: 30px;
    width: 1040px;
  }

  .unsync-table {
    position: absolute;
    margin-top: 130px;
    margin-left: 110px;

  }

  .header-up-clients {
    top: -140px;
    position: absolute;
    width: 108.5%;
    left: -75px;
    height: 40px;
    text-align: left;
    vertical-align: bottom;
    font-size: 30pt;
    background-color: rgb(201, 200, 200);
    
  }

  .header-up-clients p{
    margin-top: 5px;
   text-align: right;
  font-size: 12pt;
    font-weight: bold;
  }

  .header-clients {
    margin-top: -100px;
    position: absolute;
    width: 107%;
    left: -55px;
    height: 120px;
    text-align: left;
    vertical-align: bottom;
    font-size: 30pt;
    background-color: rgb(236, 211, 100);
    
  }

  .header-clients h3{
    margin-top: 55px;
    margin-left: 40px;
    font-weight: bold;
  }

    /* Tab Navigation */

.tab-emp ul.nav {
    width: 100%;
    margin-top: 60px;
    display: flex;
    margin-bottom: 30px;
    
  }
  .tab-emp ul.nav li {
    width: 40%;
    list-style: none;
    text-align: center;
    transition: all 0.7s;
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
    left: 0px;
  }
  ul.nav li:nth-child(2) {
    border-radius: 0;
  }
  ul.nav li:hover {
    background: rgba(198, 238, 154, 0.949);
    cursor: pointer;
  }

  .nav-link {
    width: 100%;
  }

  .td-1 {
    width: 180px;
  }

  .td-2 {
    width: 280px;
    display: inline;
  }

  .tab-content-emp {
    margin-left:-20px;
    width: 105%;
  }

  .tab-content-unsync {
    margin-left: 35px;
    width: 105%;
  }

  .custom-btn-emp {
    background-color: #58d635;
    color: #ffffff;
  }

  .custom-btn-emp:hover {
    background-color: #eeecec;
    color: #53ed28;
    border: 1px solid #53ed28;
  }

  .custom-btn-emp2 {
    background-color: #ffffff;
    color: #22e604;
    border: 1px solid #53ed28;
  }

  .custom-btn-emp2:hover {
    background-color: #52f052;
    color: #fafcfa;
    border: 1px solid #53ed28;
  }

  .modal-export {
    max-width: 90vw;
  }

  .row-filter {
    width: 230%;
    position: relative;
  }

  .placeholder{
    color: #999999;
  }

  .modalAdd {
    max-width: 60vw;
  }
  .modalDelete {
    max-width: 40vw;
    top:20%;
  }

  .h3-title {
    margin-left: 200px;
    font-size: 26pt;
  }

  .modal-body-font{
    font-size: 20pt;
  }

  .modal-height {
    height: 660px;
  }

  .modal-height-del {
    height: 300px;
  }

  .addConfirm {
    position: absolute;
    width: 44%;
    left: 52%;
    margin-bottom: 40px;
  }
.profilresign{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 16px;
}

.data-resign th {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    position: static;
    text-align: left;
    padding: 0.5em;
}
.data-resign td{
    position: relative;
    left: 10px;
    text-align: left;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
h4{
    position: relative;
    left: 0px;
}
.btnresign{
    color: red;
    background-color: transparent;
}
.Profileresign button{
    position: relative;
    margin-left: 90%;
}
/* .detres{
    margin-top: -140px;
} */
.Resign-Personal{
    color: #eb242a;
    text-decoration: underline;
    white-space: nowrap;

}
.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    rgb(207, 207, 207) 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

.shine-box {
  height: 104px;
  width: 100px;
}

/* .shine-div {
  width: 500px;
  background-color: aqua;
  display: inline-flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 15px;
  vertical-align: top;
} */

.shine-lines {
  height: 10px;
  margin-top: 10px;
  width: 200px;
}

.shine-photo {
  border-radius: 20px;
  display: block !important;
  width: 100%;
  height: 300px;
  margin-top: 15px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 968px 0;
  }
}

.shine-div {
  border-radius: 20px;
  display: block !important;
  width: 100%;
  /* height: 300px; */
  margin-top: 15px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  /* outline: black; */
  background-size: 100%, 100%;
  /* border-radius: 50%; */
  /* border: 1px solid black; */
  background-image: none;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 55px;
  color: red;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 55px;
  color: red;
}

.carousel-indicators {
  margin: 0;
}

/* Tab Navigation */

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}

@media only screen and (min-width: 320px) {
  .login-page {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
  }

  .container-login {
    padding: 30px;
    margin: 0 20px 0 20px;
  }

  .login-left-container {
    margin: 0 auto;
  }
  .img-login-container {
    text-align: left;
    margin-bottom: 20px;
  }
  .img-login-container img {
    width: 90px;
  }
  .login-right-container {
    display: none;
  }
  /* 
  .login-right-logo {
    display: none;
  } */
}

@media (min-width: 1024px) {
  .login-left-container {
    width: 50%;
  }
  .container-login {
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  .img-login-container {
    width: 100%;
    /* margin-left: -220px; */
    margin-top: 30px;
    display: block;
    text-align: left;
  }
  .img-login-container img {
    width: 120px;
    margin-left: 40px;
  }
  .form-login {
    width: 50%;
    margin: 0 auto;
  }

  .img-login-logo {
    width: 100px;
    margin-left: 70px;
  }

  .login-content-container {
    display: block;
    margin-bottom: -120px;
  }

  .login-right-logo {
    display: block;
    margin: 0 auto;
    width: 300px;
    margin-top: 200px;
  }

  .login-right-container {
    display: block;
    text-align: center;
    height: 700px;
    border-radius: 0 5px 5px 0;
    background-image: url("/public/icons/bg-login.png");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* BEST - JS calculated height */
.list-group-item {
  .card-title {
    cursor: pointer;
  }
  .card-collapse {
    overflow: hidden;
    transition: height 0.3s ease-out;
  }
  .h2 {
    margin-top: 5px !important;
  }
  /* .card-body {
   border: none !important;  why do we need this? (doesn't seem to change anything)
     } */
  /* h2 {
    margin-top: 5px !important;
  } */
}

.custom-table-idle {
    border-collapse: collapse;
  }
  .custom-table-idle td, .custom-table th {
    border: 1px solid #d9d9d9;
  }
  .custom-table-idle tr:first-child th {
    border-top: 1px solid #d9d9d9;
  }
  .custom-table tr  th:last-child td {
    border-bottom: 1px solid #d9d9d9;
  }
  .custom-table-idle tr td:first-child,
  .custom-table tr th:first-child {
    border-left:1px solid #d9d9d9;
  }
  .custom-table-idle tr td:last-child,
  .custom-table-idle tr th:last-child {
    border-right: 1px solid #d9d9d9;
  }
  
  .btn-border {
    border:1.5px solid #d9d9d9;
    color: black;
  }
  
  .btn-border:hover {
    background: #DC3545;
    color: white;
  }
  
  .rm-left-border {
  border-left: 0;
  }
    
  .content-left {
  width: 65%;
  float: left;
  }
  
  .content-right {
  text-align: center;
  margin-top: 60px;
  width: 25%;
  float: right;
  }
  

  .custom-nav-idle .nav-tabs .nav-item .nav-link.active {
    background-color: #ffffff;
    color: #d0ca0b;
    font-weight: bold;
    font-size: 14pt;
    }
  
  
  .nav-tabs .nav-item .nav-link {
  color: gray;
  border: 0.5px solid lightgrey;
  }
  
  
  .fa-cloud-arrow-down{
  color: "#dc3545"
  }
  
  .fa-filter {
  color: "#dc3545"
  }
  
  .fa:hover .fa-cloud-arrow-down{
  color: white;
  }
  
  .fa:hover .fa-filter {
  color: white;
  }
  
  .nowrap {
  white-space:nowrap;
  }
  
  .btn-custom-filter{
  background-color: #DC3545;
  color: white;
  }

  .header-up-idle {
    top: 0px;
	position: absolute;
	width: 107%;
    left: -25px;
	height: 40px;
    text-align: left;
	vertical-align: bottom;
	font-size: 30pt;
	background-color: rgb(201, 200, 200);
	
  }

  .header-up p{
    margin-top: 5px;
   text-align: right;
  font-size: 12pt;
	font-weight: bold;
  }

  .header-idle {
    margin-top: -55px;
	position: absolute;
	width: 100%;
    left: 25px;
	height: 120px;
    text-align: left;
	vertical-align: bottom;
	font-size: 30pt;
	background-color: rgb(250, 247, 87);
	
  }

  .header-idle h3{
    margin-top: 55px;
	margin-left: 60px;
	font-weight: bold;
  }

  .table-content-idle {
    position: absolute;
    margin-left: 35px;
    top: 25%;
    width: 100%;
    margin-bottom: 130px;
  }

  .table-content-function-idle {
    height: 50px;
    margin-top: 10px;
    width: 80%;
    left:10%;
    position: relative;
  }

  .table-content-function-idle-others {
    height: 30px;
    margin-top: -30px;
    margin-left: 80px;
    width: 80%;
    position: absolute;
    margin-bottom: 50px;
  }

  .link-view-pdf {
    font-size: 12pt;
    position: relative;
    left : 0.4%;
    color: blue;
    margin-top: 10px;
    top: 0%;
  }

    .filter-box{
      margin-left: 100px;
    }

    .search-bar {
      width: 100%;
      height: 120%;
      position: absolute;
    }

    .search-bar2 {
      width: 100%;
      height: 120%;
      position: absolute;
      top: -154%;
    }

    .filter-idle {
      margin-left: 100px;
    }
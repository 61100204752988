/* html,
body,
#app,
#app > div {
  height: 100vh;
} */

.App {
  text-align: center;
  margin: 0;
  padding: 0;
  /* height: 100vh; */
  /* background-color: #f0f0f0;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#wrap {
  margin: 0 auto;
}
/* #body {
  overflow:auto; 
} */

#body-main {
  display: flex;
}

#sidebar {
  float: left;
  /* min-height: 400px; */
  position: sticky;
  top: 0;
  z-index: 20;
  height: auto;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-image: linear-gradient(to right, #bd191e 1%, #c72225 1%);
  border-radius: 0 20px 20px 0; */
}

#header {
  top: 0;
  position: sticky;
  z-index: 20;
}

#main-content {
  margin-top: -24px;
  padding-top: 25px;
  min-height: 96vh;
  background-image: url("/public/icons/bg-body.png");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 50px;
}

.custom-container {
  /* font-size: 24px; */
  /* width: 100%; */
  margin: 0 auto;
  /* padding: 4px; */
  /* max-width: 1100px; */
  /* width: 95%; */
  /* max-width: 1100px; */
  /* transition: 0.1s; */
}

.expand-custom-container {
  /* width: 95%; */
  max-width: 1100px;
  /* transition: 0.1s;  */
}

@media only screen and (min-device-width: 320px) {
  /* @media only screen and (min-device-width: 1024px) { */
  #body-main {
    /* display: block; */
  }
  #sidebar {
    position: absolute;
    top: 50px;
    height: 100vh;
  }
  .side-nav-container-NX {
    margin-left: -90px;
  }
  #main {
    display: flex;
  }
  .custom-flex {
    display: block;
  }
  .custom-flex-row {
    display: block;
  }
  .custom-container {
    max-width: 900px;
    /* width: 300px; */
  }
  /* .expand-custom-container {
    max-width: 700px;
    width: 700px;
  } */
  #main-content {
    width: 100vw;
  }
}

@media only screen and (min-device-width: 1024px) {
  #main-content {
    width: 70vw;
  }
  #body-main {
    display: flex;
  }
  #sidebar {
    position: sticky;
    top: 0;
  }
  #main-content {
    flex: 1;
    /* margin: 0 auto; */
    /* align-self: center; */
    /* font-size: 32px; */
  }
  .custom-container {
    max-width: 900px;
    width: 900px;
  }
  .side-nav-container-NX {
    margin-left: 0;
  }
  .custom-flex {
    display: flex;
  }
  .custom-flex-row {
    display: flex;
    flex-direction: row;
  }
  /* .custom-container {
    margin: 0 auto;
    width: 60%;
  } */
  .expand-custom-container {
    max-width: 750px;
    width: 750px;
  }
}

/* @media only screen and (min-device-width: 1280px) {
  .custom-container {
    max-width: 900px;
  }
  .expand-custom-container {
    max-width: 800px;
  }
} */

@media only screen and (min-device-width: 1366px) {
  .custom-container {
    max-width: 1100px;
    width: 1100px;
  }
  .expand-custom-container {
    max-width: 900px;
    width: 900px;
  }
  .small-chart {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-device-width: 1600px) {
  .custom-container {
    max-width: 1300px;
    width: 1300px;
  }
  .expand-custom-container {
    max-width: 1100px;
    width: 1100px;
  }
}

@media only screen and (min-device-width: 1900px) {
  .custom-container {
    max-width: 1600px;
    width: 1600px;
  }
  .expand-custom-container {
    max-width: 1400px;
    width: 1400px;
  }
  .small-chart {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* .container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl{
  --bs-gutter-x:1.5rem;
  --bs-gutter-y:0;
  width:100%;
  padding-right:calc(var(--bs-gutter-x) * .5);
  padding-left:calc(var(--bs-gutter-x) * .5);
  margin-right:auto;
  margin-left:auto
} */
/* @media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1420px;
  }
} */

/* @media (min-width: 1024px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 600px;
  }
} */

.custom-text-12 {
  font-size: 12px;
}
.custom-text-14 {
  font-size: 14px;
}
.custom-text-16 {
  font-size: 16px;
}
.custom-text-18 {
  font-size: 18px;
}
.custom-text-20 {
  font-size: 20px;
}
.custom-text-22 {
  font-size: 22px;
}
.custom-text-24 {
  font-size: 24px;
}
.custom-text-28 {
  font-size: 28px;
}

.react-datepicker__close-icon::after {
  background-color: #dc3545 !important;
}

.DraftEditor-root {
  z-index: 9999 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

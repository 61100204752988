.custom-table {
  border-collapse: collapse;
}
.custom-table td,
.custom-table th {
  border: 1px solid #d9d9d9;
}
.custom-table tr:first-child th {
  border-top: 1px solid #d9d9d9;
}
/* .custom-table tr:last-child td {
    border-bottom: 0;
  } */
.custom-table tr td:first-child,
.custom-table tr th:first-child {
  border-left: 1px solid #d9d9d9;
}
.custom-table tr td:last-child,
.custom-table tr th:last-child {
  border-right: 1px solid #d9d9d9;
}

.btn-border {
  border: 1.5px solid #d9d9d9;
  color: black;
}

.btn-border:hover {
  background: #dc3545;
  color: white;
}

.rm-left-border {
  border-left: 0;
}

.tabs-content {
  /* min-width: 750px; */
  padding: 20px;
  border: 1px solid #d9d9d9;
  margin-top: -1px;
  border-radius: 0 0 10px 10px;
  background-color: white;
}

/* .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .modal {
  width: auto;
  height: 800px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 10px #cccccc;
  border-radius: 10px;
  z-index:1000;
}

.modal-dialog {
  width: 1200px;
}

.modal-content {
  width: auto;
} */

.modal-profile {
  max-width: 1000px;
}
.content-left {
  width: 65%;
  float: left;
}

.content-right {
  text-align: center;
  margin-top: 60px;
  width: 25%;
  float: right;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #dc3545;
  color: white;
  font-weight: 500;
}

.nav-tabs .nav-item .nav-link {
  color: gray;
  border: 0.5px solid lightgrey;
  background-color: white;
  font-weight: 500;
}

/* FILE UPLOAD */

.drop-file-input {
  position: relative;
  width: 100%;
  height: 200px;
  border: 2px dashed black;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 50px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  /* background-color: var(--input-bg); */
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: gray;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  font-size: 20;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

.custom-file-upload {
  /* opacity: 0; */
  color: transparent;
  position: absolute;
  top: 10;
  left: 0;
  width: 35%;
  height: 40px;
  cursor: pointer;
  /* border: solid black; */
  margin-left: 20px;
  color: red;
  font-weight: 500;
}

.custom-file-upload input {
  opacity: 0;
  position: absolute;
  margin-left: -110px;
  margin-top: -5px;
  color: transparent;
  width: 100%;
  height: 100%;
  border: solid black;
}

.custom-file-upload input:hover {
  color: transparent;
  width: 100%;
  height: 100%;
  background-color: #dc3545;
  color: white;
}

.fa-cloud-arrow-down {
  color: "#dc3545";
}

.fa-filter {
  color: "#dc3545";
}

.fa:hover .fa-cloud-arrow-down {
  color: white;
}

.fa:hover .fa-filter {
  color: white;
}

.nowrap {
  white-space: nowrap;
}

.btn-custom-filter {
  background-color: #dc3545;
  color: white;
}

/* NX = not expanded */

.side-nav-container {
  /* background-image: linear-gradient(
    to right,
    rgba(189, 25, 30, 1),
    rgba(189, 25, 30, 0.8)
  ); */
  /* background-color: rgba(189, 25, 30, 100); */
  width: 265px;
  min-height: 100vh;
  overflow-y: auto;
  /* height: 100vh; */

  position: sticky;
  color: white;

  background-image: linear-gradient(to right, #bd191e 1%, #c72225 1%);
  border-radius: 0 20px 20px 0;

  transition: 0.4s;
  /* z-index: 1000; */
}

.sidebar-content {
  /* overflow-y: auto; */
  /* background-image: linear-gradient(to right, #bd191e 1%, #c72225 1%); */
  /* height: auto; */
  /* height: 100%; */
  /* border-radius: 0 20px 20px 0; */
}

/* .side-nav-container a:hover {
	width: 85px;
} */

.side-nav-container-NX {
  width: 85px;
}

.nav-upper,
.nav-heading,
.nav-menu,
.menu-item,
.nav-footer {
  /* border: 2px solid white; */
  display: grid;
}

/* .nav-heading {
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  height: 75px;
} */

.nav-logo {
  margin-top: 25px;
  transition: 0.4s;
}

.nav-logo img {
  width: 164px;
}

.nav-logo-NX {
  display: none;
}

.sidenav-item-text {
  font-weight: 600;
  font-size: 16px;
  margin-top: -30px;
  margin-left: 35px;
}

/* .hamburger {
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 30px;
  font-weight: bolder;
} */
/* 
.hamburger span {
  display: block;
  margin-top: 5px;
  background-color: white;
  border-radius: 15px;
  height: 5px;
  width: 35px;

  transition: 0.4s;
}

.hamburger:hover span {
  background-color: blue;
}

.hamburger-in:hover span:nth-child(1) {
  width: 25px;
  transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
  width: 40px;
}
.hamburger-in:hover span:nth-child(3) {
  width: 25px;
  transform: translateY(-4px) rotate(25deg);
}

 .hamburger-out {
  margin-left: 24px;
}
.hamburger-out:hover span:nth-child(1) {
  width: 25px;
  transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
  width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
  width: 25px;
  transform: translate(14px, -4px) rotate(155deg);
} */

.nav-menu {
  grid-template-rows: repeat(7, 1fr);
  margin-top: 20px;
  padding-left: 15px;
}

.nav-menu-NX {
  margin-top: 175px;
  margin-left: -50px;
}

.menu-item {
  padding-top: 10px;
  height: 50px;
  text-align: left;
  /* display: flex; */
  /* flex-direction: row; */
  color: white;
  text-decoration: none;
  margin-left: -15px;
  margin-right: 15op;
  padding-left: 35px;
  /* text-transform: uppercase; */
  /* margin: auto 20px; */
  border-radius: 0 20px 20px 0;
}

.menu-item h5 {
  margin-top: -30px;
  padding-left: 25px;
}

.menu-item-NX {
  /* margin: auto; */
  margin-left: 30px;
}

.menu-item-NX:hover {
  /* margin: auto; */
  color: white;
  background: linear-gradient(
    to right,
    white 12%,
    #bd191e 2% /* #bd191e 5%,
    #bd191e 50%, */
  );
}

.menu-item:hover {
  /* background-color: #bd191e; */
  color: white;
  background-color: #bd191e;
  /* background: linear-gradient(
    to right,
    white 2%,
    #bd191e 2% 
    white 0%,
    #bd191e 5%,
    #bd191e 50%,
    #bd191e 100% 
  ); */
}

.menu-item-NX-active {
  color: white;
  background: linear-gradient(
    to right,
    white 12%,
    #bd191e 2% /* #bd191e 5%,
    #bd191e 50%, */
  );
}

.menu-item-active {
  /* background-color: #bd191e; */
  color: white;
  background: linear-gradient(
    to right,
    white 2%,
    #bd191e 2% /* #bd191e 5%,
    #bd191e 50%, */
  );
}

.menu-item img {
  width: 18px;
  height: 18px;
}

.nav-footer {
  width: 100%;
  height: 87px;
  position: absolute;
  bottom: 0;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 1fr;
}

.nav-details {
  display: flex;
}
.nav-details img {
  width: 50px;
  padding: 0 20px;
}

.nav-footer-user-name {
  font-size: 18px;
  font-weight: 900;
}

.nav-footer-user-position {
  margin-top: -15px;
  color: gray;
}
.logout-icon {
  width: 30px;
  margin: auto;
  border-radius: 90px;
  padding: 20px;
  margin-left: 5px;
}
.logout-icon:hover {
  background-color: blue;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.sub-menu-item-text {
  font-weight: 600;
  font-size: 16px;
  /* margin-top: -30px; */
  /* margin-left: 30px; */
}

.sub-menu-item {
  /* padding-top: 10px; */
  height: 50px;
  display: flex;
  color: white;
  text-decoration: none;
  /* margin-left: -35px; */
  /* padding-left: 30px; */
  /* text-transform: uppercase; */
  /* margin: auto 20px; */
  /* border-radius: 0 20px 20px 0; */
  /* margin: 0; */
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 20px;
  margin-right: 20px;
  /* border: 1px solid black; */
}

.sub-menu-item:hover {
  /* background-color: #bd191e; */
  /* color: #bd191e;
  background-color: rgba(252, 252, 252, 0.3); */
  /* background: linear-gradient(
    to right,
    white 2%,
    #bd191e 2%  white 0%,
    #bd191e 5%,
    #bd191e 50%,
    #bd191e 100% 
  ); */
  color: white;
  text-decoration: none;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #e05f5f;
  /* padding: 9px 20px 9px 20px; */
}

.sub-menu-item-active {
  /* background-color: #bd191e; */
  color: white;
  border-radius: 10px;
  /* margin-left: 20px;
  margin-right: 20px; */
  /* margin: 0;
  padding: 0;
  margin-left: 20px;
  margin-right: 30px; */
  /* padding: 10px 0 10px 0; */
  background-color: #e05f5f;
  /* padding: 9px 20px 9px 20px; */

  /* background: linear-gradient(to right, black 2%, white 2%); */
}

.nav-sub-menu {
  transition: 1s;
}

.item-menu-dropdown-btn {
  margin-top: -40px;
}

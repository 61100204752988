.modalAdd2 {
  max-width: 30vw;
}

.modal-height2 {
  height: 360px;
}

.modal-text {
  font-size: 14pt;
  margin-top: 80px;
  margin-bottom: 20px;
}

.btnSuccess {
  background-color: #20bd2e;
  position: absolute;
  width: 125px;
  color : #ffffff;
  font-size: 18pt;
  margin-top: 120px;
  left: 36%;
  border-radius: 15px;
  border-color: transparent;
}

.modal-success {
  color: #20bd2e;
  margin-top: 18px;
  font-size: 24pt;
  font-weight: bold;
  margin-bottom: -90px;
}

.modal-warning {
  color: #b6cf43;
  margin-top: 18px;
  font-size: 24pt;
  font-weight: bold;
  margin-bottom: -90px;
}

.btnWarning {
  position: absolute;
  background-color: #c8d330;
  width: 125px;
  color : #ffffff;
  font-size: 18pt;
  margin-top: 120px;
  border-radius: 15px;
  left: 36%;
  border-color: transparent;
}

.btnSuccess:hover {
  background-color: #77d17e;
}
#menuToggle {
  cursor: pointer;
  /* border: 3px solid rgba(189, 25, 30, 1); */
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 10px;
  /* padding: 5px 3px 0 3px; */
}

#menuToggle span {
  display: block;
  width: 25px;
  height: 4px;
  margin-bottom: 4px;
  position: relative;
  background: rgba(189, 25, 30, 1);
  border-radius: 3px;
}

.profile-header {
  margin-top: 25px;
  margin-left: 25px;
}

.header-btn-profile {
  cursor: pointer;
}

.modal-btn-profile {
  margin-right: 20px !important;
  margin-top: 60px !important;
  padding: 0;
}

.profile-picture {
  background-color: gray;
  padding: 12px;
  margin: 0;
  border-radius: 50px;
}

.profile-detail-container {
  margin-left: 15px;
}

.profile-detail-name {
  /* margin-top: 3px; */
  font-weight: 700;
  font-size: 18px;
}

.profile-detail-email {
  margin-top: -18px;
  color: gray;
  font-size: 14px;
}

.profile-body {
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
}

.profile-body-item {
  padding: 10px 20px 10px 20px;
}

.profile-body-item:hover {
  background-color: #f3f3f3;
  border-radius: 10px;
  cursor: pointer;
}

.profile-body-detail {
  margin-left: 30px;
  font-size: 16px;
  color: #525555;
  font-weight: 500;
  cursor: pointer;
}

.navbar-brand {
  padding: 0;
  margin-left: 20px;
  font-weight: 600;
  font-size: 20px;
  font-weight: 600;
}

.nav-brand {
  display: flex;
  color: white;
}
.nav-brand img {
  width: 40px;
  padding: 0 10px;
}

.table-custom-superadmin {
  width: 100%;
  border-collapse: collapse;
  border-bottom: 1px solid #aaaaaa;
  table-layout: initial;
  overflow-wrap: break-word;
}

.table-custom-superadmin th {
  padding: 10px 0 10px 20px;
  border-top: none;
}

.table-custom-superadmin td {
  padding: 10px 0 10px 20px;
  border-top: 1px solid #aaaaaa;
}

.table-custom-superadmin-name {
  text-align: left;
  width: 250px;
  color: black;
  font-weight: 600;
  font-size: 16;
}

.table-custom-superadmin-email {
  width: 300px;
  text-align: left;
  width: 250px;
  color: #898989;
  font-size: 12;
}

.table-custom-superadmin-position {
  color: black;
  font-weight: 600;
  font-size: 16;
}

/* .table-custom-superadmin tr td:last-child {
  width: 1%;
  white-space: nowrap;
} */
